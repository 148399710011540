import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { GetVideoPreviewModalQuery } from '~/__generated__/graphql'
import { previewVideoState } from '~/recoil/preview'
import { GET_VIDEO_PREVIEW_MODAL_QUERY } from '../components/VideoPreviewModal/graphql/queries/GetVideoPreviewModal'

export function useVideoPreviewModalInfo() {
  const previewVideoId = useRecoilValue(previewVideoState)
  const { data: previewVideo, loading } = useQuery<GetVideoPreviewModalQuery>(GET_VIDEO_PREVIEW_MODAL_QUERY, {
    variables: { id: previewVideoId },
    skip: previewVideoId === undefined,
  })
  return useMemo(() => {
    if (
      loading ||
      !previewVideo ||
      !previewVideo.originalVideo
    ) {
      return 'loading'
    }
    return previewVideo.originalVideo
  }, [loading, previewVideo])
}
