import { gql } from '@apollo/client'

export const GET_VIDEO_PREVIEW_MODAL_QUERY = gql`
query GetVideoPreviewModal($id: ID!) {
  originalVideo(id: $id) {
    id
    status
    title
    description
    willBePublishedAt
    creator {
      avatar
      name
    }
    duration {
      minuteUnitName
      minutes
      secondUnitName
      seconds
    }
    subtitles {
      label
      url
      default
      locale
    }
    trailer {
      description
      fileUrl
      vtts {
        default
        locale
        label
        url
      }
      subtitles {
        label
        default
        locale
        url
      }
    }
  }
}
`