import { useCallback } from 'react'
import browserEnv from '~/libs/env/browserEnv'
import * as GA from './ga'

export type ShareItemType = 'url' | 'x' | 'facebook'

interface ShareModalViewModal {
  onClickShareItem: (itemType: ShareItemType) => void
}

interface ShareModalViewModalProps {
  previewVideoId: string
  title: string
  description: string
}

export function useShareModalViewModal({ previewVideoId, title, description }: ShareModalViewModalProps): ShareModalViewModal {

  const onClickShareItem = useCallback(
    (item: ShareItemType) => {
      GA.shareItemClick(Number(previewVideoId))
      const url = `${ browserEnv.NEXT_PUBLIC_HOST }/videos/${ previewVideoId }`
      switch (item) {
        case 'url':
          navigator.clipboard.writeText(url)
          break
        case 'x':
          window.open(
            `https://x.com/intent/tweet?url=${ url }%0a&text=${ title }%0a${ description }%0a&hashtags=samansa`,
            '_blank',
          )
          break
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${ url }`, '_blank')
          break
      }
    },
    [description, previewVideoId, title],
  )

  return { onClickShareItem }
}
