import * as GA from '~/libs/ga'

export function shareItemClick(id: number) {
  GA.sendEvent({
    event: 'share',
    screen: 'movie_page',
    category: 'icon',
    action: 'movie_page_share',
    label: `${ id }`,
  })
}
